.cc{
    height: calc(61% + 200px);
}

.barlow{
    font-family: 'Barlow', sans-serif;
}

.resultResearchButton{
    height: 60px;
    background-color: #ff0f64;
    margin: auto;
    margin-top: 10px;
    width: 12%;
    border-radius: 14px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media screen and (max-width: 700px){
    .cc{
        height: unset;
    }

}

@media screen and (max-width: 1024px){
    .resultResearchButton{
        width: 50%;
        height: 60px;
        align-self: center;
    }
}