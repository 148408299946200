.discTitle{
    font-size: 5rem;
    color: rgba(255, 15, 100, 0.9);
    margin-left: 20px;
}

.sideText{
    color: #ECF0F1;
    margin: 0;
    white-space: nowrap;
    text-align: left;
    transform-origin: bottom; 
    margin-top: 280px;
    margin-left: 80%;
    transform: rotate(270deg);
}

.textContent{
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.link{
    font-size: 5rem;
}

.op{
    font-size: 5rem;
}

.contactText{
    font-size: 1.5rem;
    color: #ECF0F1;
    font-weight: bold;
    margin-left: 20px;
}

.contactSection{
    display: flex;
    margin-left: 20px;
}

.contactInfo{
    font-size: 1.6rem;
    color: #ECF0F1;
    font-weight: 500;
}

.aOTitle{
    font-size: 5rem;
}

.otherText{
    font-size: 1.6rem;
}

.iframec{
    margin: auto;
    width: 500px;
}

@media screen and (max-width: 700px){

    .iframec{
        width: auto;
        margin: 0;
    }

    .link{
        font-size: 9vw;
    }

    .textContent{
        width: 97%;
    }

    .discTitle{
        font-size: 4rem;
        margin-left: 8px;
    }

    .op{
        font-size: 3rem;
    }

    .contactSection{
        flex-direction: column;
        align-items: center;
        margin: 5px;
    }

    .contactInfo{
        font-size: 1.5rem;
    }

    .aOTitle{
        font-size: 3rem;
    }


    .otherText{
        font-size: 1.2rem;
    }
}