.connectionData{
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    font-size: 5rem;
}

.connectionDataNames{
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    font-size: 3rem;
}

.connectionDataUnit{
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    font-size: 2rem;
}

.dSpeed{
    font-size: calc(5rem + 30%);
    margin-top: -10px;
}

.dataTable td{
    border: 1px solid #dee2e6;
    padding: 2px;
    font-size: 100%;
}

.dataTable th{
    padding: 10px;
    font-size: 24px;
    color: #464949;
    font-weight: 600;
    text-align: left;
}

.tableSection{
    width: 50%;
    margin: auto;
    margin-bottom: 4px;
    transition: all 0.25s;
}

.tableSectionOpen{
    transition: all 0.3s;
    width: 80%;
    margin: auto;
}

.tableSectionTitle{
    color: #ff0f64;
}

.gigabit{
    font-size: 3vw;
    color: #464949;
    margin-top: auto;
}

@media screen and (max-width: 700px){

    .tableSectionTitle{
        font-size: 20px;
    }

    .gigabit{
        text-align: center;
        width: 95%;
        font-size: 23px !important;  
        line-height: 1.6;
    }

    .connectionData{
        font-size: 2rem;
    }

    .connectionDataNames{
        font-size: 20px;
    }
    
    .connectionDataUnit{
        font-size: 20px;
    }

    .dSpeed{
        font-size: calc(2rem + 50%);
    }

    .tableSection{
        width: 100%;
    }

    .dataTable th{
        font-size: 1.9ch;
    }

    .dataTable td{
        font-size: 90%;
    }
}