.form1{
    place-items: center ;
    margin-top: 10px;
    margin-bottom: 30px;
    display: grid;
    z-index: 5;
    grid-template-areas: 
    "a a a a"
    "a a a a"
    "a a a a"
    "c c c c"
    ". b b ."
    ;
    grid-row-gap: 10px;
    min-height: calc(100vh - 332px - 93px - 40px);
}
.sub{
    font-family: 'Barlow', sans-serif;
    grid-area: b;
    width: 200px;
    height: 60px;
    border-color: rgb(255,15,100);
    background-color: white;
    border-radius: 10px;
    border-width: 2px;
    border-collapse: separate; 
    color: #ff0f64;
    font-size: 30px;
    margin-top: 4px;
} 
.sub:hover{
    border-color: grey;
    border-width: 1px;
    color: grey;
}
.Loading{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: auto; 
    grid-area: b;
}
.result{
    font-size: 30px;
    font-family: 'Barlow', sans-serif; 
    text-align: center;
    grid-area: b;
    margin-top: 10px; 
     
}
.privacy{
    grid-area: c;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
}
#Submitted{
    min-height: calc(100vh - 332px);
}
@media screen and (max-width: 700px) {
    .form1{
        place-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        display: grid;
        z-index: 5;
        
        grid-template-areas: 
        "a ";
       flex-grow: 1;
       grid-row-gap: 0px;
       margin-top: 0px;
    }
    .sub{
        grid-area: auto;
    }
    .Loading{
        grid-area: auto;
    }
    .result{
        grid-area: auto;
    }
    .privacy{
        grid-area: auto;
        margin-left: 10px;
    }
}


@media screen and (min-width: 700px ) and  (max-width: 900px) {
    .form1{
        margin-top: 30px;
        margin-bottom: 30px;
        display: grid;
        z-index: 5;
        grid-template-areas: 
        "a a"
        "a a"
        "a a" 
        "a a"
        "a a"
        "a a"
        "c c"
        "d d"
        ;
        grid-template-columns: 50% 50%;
    }
    .sub{
        grid-area: d;
    }
    .Loading{
        grid-area: auto;
    }
    .result{
        grid-area: auto;
    }
    .privacy{
        text-align: center;
        text-align-last: center;
        grid-area: c;
    }
    #subContact{
        margin-left: 0%;
    }
    #contactme{
        grid-template-areas: 
        "a a"
        "a a"
        "a a" 
        "a a"
        "c c"
        "d d"
        ;
    }
}