.textTitle{
    font-family: 'Barlow', sans-serif;

}

.textContainer{
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.titleSection{
    width: 100%;
    background-color: rgb(223, 26, 95);
    color: white;
    min-height: 400px;
}

.textContainer > p{
    font-size: 24px;
    margin: 10px;
}

.titleSection > h1{
    margin: auto;
    font-size: 3.4rem;
    width: 60%;
    font-weight: 700;
    
}

.titleSection > p{
    margin: auto;
    font-size: 24px;
    width: 60%;
    
}

.textContainer > h2{
    margin: 0;
}


@media screen and (max-width: 700px) {



    .textContainer{
        width: 100%;
    }

    .titleSection > h1{
        width: 95%;
        margin: auto;
        font-size: 3.4rem;
        
    }
    
    .titleSection > p{
        width: 95%;
        margin: auto;
        font-size: 24px;
        
    }

    .textContainer > h1{
        padding-left: 10px;
    }

    .textContainer > h2{
        padding-left: 10px;
    }

}