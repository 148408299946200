.bg {  
  background: rgb(243,17,98);
  background: linear-gradient(347deg, rgba(243,17,98,0.927608543417367) 8%, rgba(41,44,59,1) 61%);
  background-size: contain;
  z-index: -20;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

html {
  height: 100%;
}


