.cContainer{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px;
    z-index: 10;
    /* justify-content: space-between; */
}

.cButton{
    min-height: 30px;
    margin-left: 30px;
    background-color: #ff0f64;
    border: 0.3rem solid transparent;
    border-radius: 3rem;
    font-weight: bold;
    color: white;
    font-size: 12px;
    padding: 3px;
}

.ct{
    margin-left: 2vw;
}

@media screen and (max-width: 700px) {
    .cContainer{
        flex-direction: column;
        padding-bottom: 10px;
    }
    
    .ct{
        width: 95%;
    }
}