.connectionPhrase{
    font-size: 3.2rem;
}

.face{
    width: 10%;
}

.detalis{
    text-align: left;
    width: 95%;
}

@media only screen and (max-width: 700px) {
    .connectionPhrase{
        font-size: 1.8rem;
    }

    .face{
        width: 30%;
    }

    .detalis{
        text-align: center;
        font-size: 1.3rem;
    }
}