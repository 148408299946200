a:link{
    text-decoration: none;
}

.podiumContainer{
    min-height: 400px;
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 15px;
    padding: 40px;
    padding-top: 60px;
    padding-bottom: 0;
}

.pStep{
    /* position: relative; */
    width: 100%;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin-top: auto;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;

}

.itemPic{
    position: relative;
    top: -40%;
    width: 70%;
    transition: transform .2s;
}

.pStep:hover>.itemPic{
    transform: scale(1.2);
}

.topListTitle{
    background: rgb(255,15,100);
    background: linear-gradient(90deg, rgba(255,15,100,1) 38%, rgba(121,9,81,1) 97%);
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
    text-align: center;
    color: white;
}


.topListItem{
    margin: auto;
    margin-top: 20px;
    width: 98%;
    height: 80px;
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: transform .2s;

    
}

.topListItem:hover{
    transform: scale(1.05);
}

.listPos{
    color: #ff0f64;
    font-size: 2.5rem;
    margin: 5%;
    font-display: swap;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
}

.listItemName{
    color: #8C827F;
    font-size: 2.6rem;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    font-display: swap;

}

.amazonBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff0f64;
    border-radius: 8px;
    width: 100px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.recM{
    width: 95%;
}

.topitemInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

@media screen and (max-width: 700px) {

    .recM{
        width: 98%
    }

    .podiumContainer{
        height: 100px;
        padding-top: 0;
        align-items: center;
        width: 100%;
        justify-content: space-around;
    }

    .topListItem{
        height: fit-content;
    }

    .pStep{
        min-width: 90px;
    }

    .itemPic{
        width: 100%;
        margin: 0;
    }

    .listItemName{
        font-size: 6.3vw;
    }


    .topitemInfo{
        flex-direction: column;
    }

    .amazonBtn{
        width: 70%;
        padding: 2px;
        margin-right: auto;
    }
}