html{
    height: 100%;
}

.fContainer{
    height: 200px;
    background-color: rgba(255, 15, 100 , 0.8);
    width: 100%;
    display: flex;
    border-top: 2px solid white;
    flex-direction: row;
    color: white;
}

.infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.infos > p{
    margin: 5px;
}

.fLogo{
    width: 250px;
}

@media screen and (max-width: 700px) {
    .fLogo{
        /* margin-top: -30px; */
        align-self: center;
        margin-bottom: 20px;
    }

    .infos{
        margin-top: -20px;
        align-items: center;
    }

    .infos > p{
        width: 98%;
        text-align: center;
    }

    .fContainer{
        bottom: 0;
        flex-direction: column;
        height: fit-content;
        justify-content: center;
        padding-bottom: 10px;
    }
}